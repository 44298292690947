import React from 'react';

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.19477 121.27944">
        <g
            transform="translate(0.12119923,0.2528171)">
            <g
                transform="matrix(0.26458333,0,0,0.26458333,-0.16353252,-0.2528171)">
                <path d="M 107.103,53.311001 A 53.311001,53.311001 0 0 1 53.792,106.622 53.311001,53.311001 0 0 1 0.48099899,53.311001 53.311001,53.311001 0 0 1 53.792,0 53.311001,53.311001 0 0 1 107.103,53.311001 Z"
                ></path>
                <path d="M 458.21899,53.311001 A 53.311001,53.311001 0 0 1 404.90799,106.622 53.311001,53.311001 0 0 1 351.59699,53.311001 53.311001,53.311001 0 0 1 404.90799,0 a 53.311001,53.311001 0 0 1 53.311,53.311001 z"
                ></path>
                <path d="M 282.66101,53.311001 A 53.311001,53.311001 0 0 1 229.35001,106.622 53.311001,53.311001 0 0 1 176.03901,53.311001 53.311001,53.311001 0 0 1 229.35001,0 a 53.311001,53.311001 0 0 1 53.311,53.311001 z"
                ></path>
                <path d="M 106.784,229.19 A 53.312,53.312 0 0 1 53.472,282.502 53.312,53.312 0 0 1 0.15999985,229.19 53.312,53.312 0 0 1 53.472,175.878 53.312,53.312 0 0 1 106.784,229.19 Z"
                ></path>
                <path d="m 457.89901,229.19 a 53.312,53.312 0 0 1 -53.312,53.312 53.312,53.312 0 0 1 -53.312,-53.312 53.312,53.312 0 0 1 53.312,-53.312 53.312,53.312 0 0 1 53.312,53.312 z"
                ></path>
                <path d="M 282.342,229.19 A 53.312,53.312 0 0 1 229.03,282.502 53.312,53.312 0 0 1 175.718,229.19 53.312,53.312 0 0 1 229.03,175.878 53.312,53.312 0 0 1 282.342,229.19 Z"
                ></path>
                <path d="m 106.783,405.06799 a 53.311001,53.311001 0 0 1 -53.311,53.311 53.311001,53.311001 0 0 1 -53.3110007,-53.311 53.311001,53.311001 0 0 1 53.3110007,-53.311 53.311001,53.311001 0 0 1 53.311,53.311 z"
                ></path>
                <path d="m 457.89801,405.06799 a 53.311001,53.311001 0 0 1 -53.311,53.311 53.311001,53.311001 0 0 1 -53.31101,-53.311 53.311001,53.311001 0 0 1 53.31101,-53.311 53.311001,53.311001 0 0 1 53.311,53.311 z"
                ></path>
                <path d="m 282.341,405.06799 a 53.311001,53.311001 0 0 1 -53.311,53.311 53.311001,53.311001 0 0 1 -53.311,-53.311 53.311001,53.311001 0 0 1 53.311,-53.311 53.311001,53.311001 0 0 1 53.311,53.311 z"
                ></path>
            </g>
        </g>
    </svg>
);
