import React from 'react';

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.063 68.063">
        <g>
            <path d="M6.032,21.936h8.96l2.688,7.84h3.584L12.753,4.464H8.72L0.208,29.776h3.36L6.032,21.936z M9.169,12.08
                    c0.447-1.567,0.896-3.137,1.344-4.479l0,0c0.448,1.567,0.672,2.912,1.344,4.704l2.464,7.168H6.704L9.169,12.08z"
            ></path>
            <path d="M47.695,50.383c0-1.344-0.896-3.358-2.464-6.047c0-0.896,0.672-2.018,1.792-3.139c1.119-1.344,2.017-2.238,2.24-2.91
                    c-0.448-0.674-1.344-1.121-2.465-1.568c-0.225,0-0.896,0.447-2.463,1.345c-1.121,0.449-1.793,0.896-2.466,0.896
                    c-0.225,0-0.672,0-1.118-0.225c-0.449-0.223-0.896-0.223-1.121-0.223H38.96c0.447,0.672,0.672,1.344,0.672,1.568v17.471
                    c0,0.449,0,1.121-0.225,2.018s-0.225,1.566-0.225,2.016c0,1.121,0.447,1.793,1.119,2.24l0.896-0.225
                    c0.226,0,0.226-0.895,0.447-2.688c0-1.791,0-4.703,0-8.513c0-1.346,0-3.358,0-6.271s0-4.928,0-6.271
                    c2.239-0.672,3.809-1.121,4.929-1.568l0.225,0.226v0.226c-0.447,0.895-0.672,1.791-1.119,2.465
                    c-0.672,1.344-0.896,2.016-0.896,2.463c0,0.225,0.226,0.896,0.672,2.018c0.447,1.344,0.673,2.016,0.896,2.24l-0.225,0.447
                    c-1.345-0.228-2.688-0.447-4.031-0.674c0.672,0.227,1.566,0.896,2.688,1.793c1.122,0.896,2.017,1.565,2.464,1.791
                    C47.471,51.504,47.695,51.055,47.695,50.383z"
            ></path>
            <path d="M64.271,46.127c1.568,0,2.688-0.225,3.137-0.446c0-0.449-0.447-0.896-1.12-1.345c-0.672-0.448-1.118-0.448-1.792-0.448
                    c-1.12,0-2.688,0.225-4.479,0.672c-2.24,0.448-3.81,0.672-4.256,0.896c-3.584,0.447-6.272,0.672-8.064,0.896
                    c0.673,1.118,1.345,1.791,2.24,1.791c0.672,0,1.568,0,2.912-0.224c0.896-0.225,1.791-0.225,2.912-0.449
                    c0.446,0.449,0.672,0.896,0.672,1.794v11.647l-0.226,0.223l-0.446-0.223c-1.793-0.449-3.138-0.674-4.033-0.896
                    c0.673,0.446,1.346,1.344,2.24,2.463c0.896,1.121,1.567,2.019,2.017,2.465c1.567-0.896,2.465-2.688,2.465-5.601
                    c0-4.479,0-6.495,0-6.272c0-2.463-0.226-4.479-0.673-6.271C60.688,46.352,62.704,46.127,64.271,46.127z"
            ></path>
            <path d="M64.943,53.52c-1.567-0.672-3.137-1.121-4.703-1.121c0.672,0.672,2.018,1.568,3.359,3.137
                    c1.344,1.344,2.688,2.465,3.358,3.137c0.673,0,0.896-0.672,0.896-1.793C67.855,55.536,66.961,54.417,64.943,53.52z"
            ></path>
            <path d="M60.688,37.616c-0.447,0-1.12,0.225-2.239,0.447c-1.567,0.449-2.24,0.672-2.24,0.672
                    c-1.118,0.225-2.688,0.449-4.702,0.672c0,0.896,0.896,1.346,2.463,1.346c0.896,0,2.465-0.225,4.705-0.448
                    c2.463-0.447,3.808-0.673,4.03-1.119c0-0.447-0.225-0.672-0.896-1.119C61.584,37.616,61.136,37.616,60.688,37.616z"
            ></path>
            <path d="M50.607,51.727c0,0-0.225,0.449-0.672,1.344c-0.225,0.896-0.448,1.346-0.672,1.568c-0.448,0.449-0.896,1.121-1.568,1.793
                    c-0.672,0.896-0.896,1.344-0.896,1.791c0,0.225,0,0.449,0.225,0.672c0.225,0.225,0.447,0.225,0.672,0.225
                    c0.673,0,1.568-0.447,2.24-1.568c0.672-0.895,0.896-2.016,0.896-3.135C51.279,52.624,51.055,51.727,50.607,51.727z"
            ></path>
            <path d="M49.04,16.336c0,0,19.714-2.24,14.111,17.022C79.727,7.599,49.04,5.807,49.04,5.807V1.104l-15.68,10.08l15.68,10.08
                    V16.336z"
            ></path>
            <path d="M19.024,51.727c0,0-19.712,2.24-14.112-17.023c-16.575,25.76,14.112,27.553,14.112,27.553v4.703l15.68-10.08
                    l-15.68-10.08V51.727z"
            ></path>
        </g>
    </svg>
);
