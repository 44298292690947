import React from 'react';

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193.056 193.056">
        <path d="M163.022,147.499H62.417l-2.13-8.714h114.017l18.466-80.448L42.135,40.28L35.234,0H0.286v15.217h22.116l3.969,23.173
                l-0.32-0.038l18.184,100.435h0.383l2.214,9.049c-10.774,1.798-19.021,11.164-19.021,22.44c0,12.562,10.218,22.78,22.777,22.78
                c12.559,0,22.78-10.218,22.78-22.78c0-2.65-0.479-5.192-1.319-7.558h69.512c-0.837,2.369-1.319,4.91-1.319,7.558
                c0,12.562,10.218,22.78,22.775,22.78c12.562,0,22.78-10.218,22.78-22.78C185.805,157.718,175.584,147.499,163.022,147.499z
                M44.818,55.925l129.331,15.507l-11.968,52.136H56.946L46.89,68.018L44.818,55.925z M50.594,177.837
                c-4.169,0-7.56-3.393-7.56-7.563c0-4.167,3.391-7.558,7.56-7.558c4.169,0,7.56,3.394,7.56,7.558
                C58.154,174.446,54.763,177.837,50.594,177.837z M163.022,177.84c-4.167,0-7.558-3.393-7.558-7.563
                c0-4.167,3.393-7.558,7.558-7.558c4.172,0,7.563,3.393,7.563,7.558C170.588,174.446,167.194,177.84,163.022,177.84z"/>
    </svg>
);
